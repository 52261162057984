<template>
  <div>
    <crud-button
      :onClickList="fetchCozumOrtaks"
      :onClickAddNew="addNew"
    />
    <b-card no-body class="mb-0">
      <b-table
        class="position-relative"
        :items="getCozumOrtaks"
        responsive
        :fields="columns"
        primary-key="id"
        show-empty
        empty-text="Kayıt bulunamadı"
      >
        <!-- Column: User -->
        <template #cell(sLogoUrl)="data">
          <b-img :src="data.item.sLogoUrl" height="60" width="130" alt="logo" />
        </template>
        <template #cell(isActive)="data">
          <b-form-checkbox
            :checked="data.item.isActive"
            class="custom-control-success"
            name="check-button"
            @change="deactivate(data.item.id)"
          >
          {{ $t('Aktif/Pasif') }}
          </b-form-checkbox>
        </template>
        <template #cell(actions)="data">
          <!-- Update -->
          <b-button
            size="sm"
            @click="
              $router.push({
                name: 'manage-website-cozumortak-save',
                params: { id: data.item.id },
              })
            "
            variant="primary"
          >
            <span class="align-middle">{{ $t('Güncelle') }}</span>
          </b-button>

          <!-- Remove -->
          <b-button
            size="sm"
            class="ml-1 remove-button-mt-1"
            @click="remove(data.item.id)"
            variant="warning"
          >
            <span class="align-middle">{{ $t('Kaldır') }}</span>
          </b-button>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import AlertService from '@/common/alert.service'
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import CrudButton from '@/components/CrudButton.vue'

export default {
  components: {
    CrudButton,
  },
  data() {
    return {
      columns: [
        { key: 'sLogoUrl', label: 'Logo' },
        { key: 'sAciklama', label: 'Açıklama' },
        { key: 'isActive', label: 'Aktif Durum', tdStyle: 'width:5px !important', thStyle: 'width:5px !important' },
        { key: 'actions', label: 'Aksiyon', class: 'text-right' },
      ],
    }
  },
  computed: {
    getCozumOrtaks() {
      return this.$store.getters['cozumOrtakYonetim/getCozumOrtaks']
    },
  },
  methods: {
    fetchCozumOrtaks() {
      this.$store.dispatch('cozumOrtakYonetim/fetchCozumOrtaks', {})
    },

    addNew() {
      this.$router.push({ name: 'manage-website-cozumortak-save', params: { id: -1 } })
    },

    remove(id) {
      AlertService.confirmDelete(this, () => {
        this.$store.dispatch('cozumOrtakYonetim/removeCozumOrtak', id)
          .then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationSuccess,
                position: 'top-right',
                props: { text: response.message },
              })

              this.fetchCozumOrtaks();
            } else {
              AlertService.error(this, response.message)
            }
          });
      });
    },

    deactivate(id) {
      this.$store.dispatch('cozumOrtakYonetim/toggleActiveCozumOrtak', id)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            });
          } else {
            AlertService.error(this, response.message);
          }
        })
    },
  },
  
  created() {
    this.fetchCozumOrtaks()
  },
}
</script>

<style scoped>
@media (max-width: 890px) {
  .remove-button-mt-1 {
      margin-top: 10px !important;
  }
}
</style>
